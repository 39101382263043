import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addAbsence } from '../../actions/absenceActions';
import { SuccessPopup } from '../../components/popups/Popups';
import useAuth from '../../hooks/UseAuth';
import { useTranslation } from 'react-i18next'
import { getAbsenceTypes } from '../../actions/absenceTypeActions';
import { getUsers } from '../../actions/userActions';
import Select from "react-select";

export default function CreateAdminAbsence(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {auth} = useAuth()

  const {register, handleSubmit, watch, clearErrors, formState:{errors}, reset} = useForm()
  const [errMsg, setErrMsg] = useState('')

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
    e.preventDefault()
    clearErrors()
    setErrMsg('')
    setShow(false)
  }

  const [selectedUser, setSelectedUser] = useState(null)

  const {absenceTypes} = useSelector(state => state.absenceTypes)
  const {users} = useSelector(state => state.users)

  const data = {
    reason: watch('reason'),
    absenceType: watch('absenceType'),
    startDate: watch('startDate'),
    endDate: watch('endDate'),
    user: selectedUser?.value,
    status: 'Aceptada'
  }

  const CreateAbsence = async() => {
    setSelectedUser(null)
    reset({
      reason: null,
      absenceType: null,
      startDate: null,
      endDate: null,
      user: null
    })
    dispatch(addAbsence(data))
    setErrMsg('')
    setShow(false)
    SuccessPopup(`Ausencia creada correctamente!`)
  }

  useEffect(()=>{
    if (auth?.user) {
      dispatch(getUsers())
      dispatch(getAbsenceTypes())
    }
  }, [auth, dispatch])

  return (
    <>
      {
        props.button 
          ? <Button variant="primary" onClick={handleShow}>
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
              <span className="svg-icon svg-icon-2 me-3">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="11.364" y="20.364" width={16} height={2} rx={1} transform="rotate(-90 11.364 20.364)" fill="black" />
                  <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
                </svg>
              </span>
              {/*end::Svg Icon*/}
              {t('Nueva Ausencia')}
            </Button>
            : <Link to={'#'} onClick={handleShow} className="d-flex flex-column flex-center h-100 p-6 bg-hover-secondary rounded-2 bg-gray-100 bg-opacity-70  px-6 py-5" >
                {/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
                <span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black" />
                    <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black" />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                <span className="fs-6 text-gray-400">{t('Notificar')}</span>
                <span className="fs-4 fw-bold text-gray-800 mb-0">{t('ausencia')}</span>
              </Link>
      }
      

      <Modal show={show} size='lg' backdrop='static' >
        <Modal.Header closeButton onClick={handleClose} >
          <Modal.Title className='fw-bolder fs-2'>{t('Nueva Ausencia')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Modal body*/}
          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            {/*begin::Form*/}
            <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(CreateAbsence)}>
              {/*begin::Scroll*/}
              <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                <div className='fv-row mb-7'>
                  <label className="required fw-bold fs-6 mb-2">{t('Usuario')}</label>
                  <Select placeholder={t('Seleccionar Usuario')} options={users?.map(user => {return { value: user.id, label: user.username }}) || []} value={selectedUser} onChange={(selectedValue) => {setSelectedUser(selectedValue)}}/>
                </div>
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Razón')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Razon de la ausencia" {...register(
                    'reason', {
                      required: 'Este campo es obligatorio'
                      }
                  )} />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="name" data-validator="notEmpty">{errors.reason?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Tipo')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <select className="form-select form-select-solid" defaultValue={0} {...register(
                    'absenceType', {
                      required: 'Este campo es obligatorio'
                      }
                  )}>
                    <option value={0} disabled>{t('Selecciona un tipo de ausencia')}</option>
                    {
                      absenceTypes?.map((absenceType, i)=>
                        <option key={i} value={absenceType.id}>{absenceType.name}</option>
                      )
                    }
                  </select>
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="name" data-validator="notEmpty">{errors.absenceType?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Fecha de Inicio')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="date" name="startDate" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Fecha de inicio" {...register(
                    'startDate', {
                      required: 'Este campo es obligatorio'
                      }
                    )} />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="startDate" data-validator="notEmpty">{errors.startDate?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Fecha de Fin')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="date" name="endDate" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Fecha de fin" {...register(
                    'endDate', {
                      required: 'Este campo es obligatorio'
                      }
                    )} />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="startDate" data-validator="notEmpty">{errors.endDate?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/* <!--begin::Api error message--> */}
                <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
                </div>
                {/* <!--end::Api error message--> */}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-15">
                <button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
                <button className="btn btn-primary">
                  <span className="indicator-label">{t('Enviar')}</span>
                  <span className="indicator-progress">{t('Porfavor espere...')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                </button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
