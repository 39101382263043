import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import useAuth from '../../hooks/UseAuth';
import Swal from 'sweetalert2';
import { getTasks, getTasksByUser } from '../../actions/taskActions';
import { correctSheet, getDatesByUser, userSheetsByDate } from '../../actions/sheetActions';
import { getUsers, getUserSupervisor } from '../../actions/userActions';

export default function ModifySheet() {

    const {auth} = useAuth()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {users} = useSelector(state => state.users)
    const {tasks} = useSelector(state => state.tasks)
    const {sheets, dates} = useSelector(state => state.sheets)

    const [selectedTask, setSelectedTask] = useState({options: [], selected: null})
    const [selectedUser, setSelectedUser] = useState({options: [], selected: null})
    const [userSheetDates, setUserSheetDates] = useState([])
    const [selectedtDate, setSelectedDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)
    const [minDate, setMinDate] = useState(null)
    const [confirmDate, setConfirmDate] = useState(false)
    const [sheetsToModify, setSheetsToModify] = useState([])
    const [sheetsToCreate, setSheetsToCreate] = useState([])
    const [sheetsDeleted, setSheetsDeleted] = useState([])

    const handleUserChange = async (selectedValue) => {
        // Set the selected user state selected value
        setSelectedUser({...selectedUser, selected: selectedValue})
        setConfirmDate(false)
        setSheetsToCreate([])
        setSheetsToModify([])
        setSheetsDeleted([])

        dispatch(getDatesByUser(selectedValue.value))
        dispatch(getTasksByUser(selectedValue.value))
    }

    useEffect(() => {
        if (dates) {
            const responseDate = new Date()
            let maxResponseDate = new Date()
            maxResponseDate.setMonth(responseDate.getMonth() + 6)
            let minResponseDate = new Date()
            minResponseDate.setMonth(responseDate.getMonth() - 12)
            setMaxDate(`${maxResponseDate.getFullYear()}-${(parseInt(maxResponseDate.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${maxResponseDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`)
            setMinDate(`${minResponseDate.getFullYear()}-${(parseInt(minResponseDate.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${minResponseDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`)
            setSelectedDate(new Date())
            setUserSheetDates(dates)
        }
    }, [dates])

    // When a date has been confirmed
    const handleConfirmDate = async () => {
        if (userSheetDates?.dates?.find(d => d === selectedtDate)) {
            dispatch(userSheetsByDate({date: selectedtDate}, selectedUser.selected?.value))
        }else{
            setSheetsToModify([])
        }
        setConfirmDate(true)
    }

    useEffect(() => {
        if (sheets) {
            setSheetsToModify(sheets)
        }
    }, [sheets])

    const handleSheetEditChange = (target, index, value) => {
        setSheetsToModify(sheetsToModify?.map((sheet, i) => {
        if (i === index) {
            let sheetCopy = sheet
            sheetCopy[target] = value
            return sheetCopy
        } else {
            return sheet
        }
        }))
    }

    const handleSheetCreateChange = (target, index, value) => {
        setSheetsToCreate(sheetsToCreate.map((sheet, i) => {
        if (i === index) {
            let sheetCopy = sheet
            sheetCopy[target] = value
            return sheetCopy
        } else {
            return sheet
        }
        }))
    }

    const handleEditDeleteSheet = (index) => {
        let sheetsNotDeleted = []
        sheetsToModify?.forEach((s, i) => {
            if (i !== index) {
                sheetsNotDeleted.push(s)
            }
        });
        setSheetsDeleted([...sheetsDeleted, sheetsToModify[index]])
        setSheetsToModify(sheetsNotDeleted)
    }

    const handleCreateDeleteSheet = (index) => {
        let sheetsNotDeleted = []
        sheetsToCreate.forEach((s, i) => {
            if (i !== index) {
                sheetsNotDeleted.push(s)
            }
        });
        setSheetsToCreate(sheetsNotDeleted)
    }

    const handleSubmit = async () => {
        let sheets = []
        if(sheetsToModify.length > 0){
            sheetsToModify?.forEach(s => {
                sheets.push(s)
            })
        }
        if(sheetsToCreate.length > 0){
            sheetsToCreate.forEach(s => {
                sheets.push(s)
            })
        }

        try {
            dispatch(correctSheet({date: selectedtDate, sheets: sheets, deleted: sheetsDeleted, supervisor: auth?.username}, selectedUser.selected?.value))
            Swal.fire({
                title: t("Fichaje modificado correctamente"),
                text: "",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#5cb85c',
                confirmButtonText: 'Ok',
            })
            setSelectedUser({...selectedUser, selected: null})
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (auth) {
            if (auth.roles.indexOf('ROLE_ADMIN') !== -1) {
                dispatch(getUsers())
            } else {
                dispatch(getUserSupervisor(auth.user))
            }
            dispatch(getTasks())
        }
    }, [auth, dispatch])

    useEffect(() => {
        if (users) {
            let usersAvailable = []
            let usersFound = users

            if (!auth?.roles?.includes('ROLE_ADMIN')) {
                const currentUser = users?.find(u => u.username === auth?.username)
                usersFound = users.filter(u => u.supervisor === currentUser?.id)
            }
            usersFound.forEach(user => {
                const userOption = {label: `${user.lastName}, ${user.firstName}`, value: user.id}
                usersAvailable.push(userOption)
            });
            setSelectedUser({options: usersAvailable, selected: null})
        }
    }, [users, auth])

    useEffect(() => {
        if (tasks) {
            let tasksAvailable = []
            
            tasks.forEach(task => {
                const taskOption = {label: `${task.name}`, value: task.id}
                tasksAvailable.push(taskOption)
            })
            setSelectedTask({options: tasksAvailable, selected: null})
        }
    }, [tasks])

    return (
        <div>
        {/*begin::Toolbar*/}
        <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
        {/*begin::Container*/}
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
            {/*begin::Page title*/}
            <div className="page-title d-flex flex-column me-3">
            {/*begin::Title*/}
            <h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Modificación de fichajes')}</h1>
            {/*end::Title*/}
            {/*begin::Breadcrumb*/}
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                <Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item">
                <span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                <Link to='/admin' className="text-white text-hover-primary">{t('Admin')}</Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item">
                <span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white">{t('Modificar Fichaje')}</li>
                {/*end::Item*/}
            </ul>
            {/*end::Breadcrumb*/}
            </div>
            {/*end::Page title*/}
        </div>
        {/*end::Container*/}
        </div>
        {/*end::Toolbar*/}
        {/*begin::Container*/}
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        {/*begin::Steps*/}
        <div className="content flex-row-fluid" id="kt_content">
            <div className="row g-6 g-xl-9">
            {/*begin::User selection*/}
            <div className="col-12">
                {/*begin::User Selector*/}
                <div className="card h-100">
                <div className="card-body p-9">
                    <div className="fs-2x fw-bolder">{t('Selección de usuario')}</div>
                    <div className="fs-4 fw-bold text-gray-400 mb-7">{t('Seleccione usuario para continuar')}</div>
                    <Select name="user" placeholder={t('Seleccionar Usuario')} options={selectedUser.options} value={selectedUser.selected} onChange={handleUserChange}/>
                </div>
                </div>
                {/*end::Budget*/}
            </div>
            {/*end::User selector*/}
            {/*begin::Date Selection*/}
            {
                selectedUser.selected &&
                <div className="col-12">
                <div className="card h-100">
                    <div className="card-body p-9">
                    <div className="fs-2x fw-bolder">{t('Selección de fecha')}</div>
                    <div className="fs-4 fw-bold text-gray-400 mb-7">{t('Seleccione una fecha para continuar')}</div>
                        <input type="date" className="form-control form-control-solid mb-5" name="date" value={selectedtDate || ''} onChange={(e) => {setSelectedDate(e.target.value); setConfirmDate(false)}} max={maxDate || ''} min={minDate || ''}/>
                        <div className='d-grid gap-2'>
                        <button type='button' className='btn btn-info' onClick={handleConfirmDate}>{t('Seleccionar fecha')}</button>
                        </div>
                    </div>
                </div>
                </div>
            }
            {/*end::Date selection*/}
            {/*begin::Absences*/}
            {
                selectedUser.selected && confirmDate &&
                <div className="col-12">
                <div className="card h-100">
                    <div className="card-body p-9">
                    <div className="fs-2x fw-bolder">{t('Edición de ficha')}</div>
                    <div className="fs-4 fw-bold text-gray-400 mb-7">{t('Edite la ficha del usuario seleccionado')}</div>
                        {sheetsToModify?.length > 0
                            ?
                                sheetsToModify?.map((sheet, key) => (
                                    <div className='row my-3' key={key}>
                                        <div className='col-md-3 col-sm-6'>
                                            <label className="required fw-bold fs-6 mb-2">{t('Hora de entrada')}</label>
                                            <input className='form-control form-control-solid' type="time" required value={sheet.checkInTime} onChange={(e) => {handleSheetEditChange('checkInTime', key, e.target.value)}}/>
                                        </div>
                                        <div className='col-md-3 col-sm-6'>
                                            <label className="required fw-bold fs-6 mb-2">{t('Hora de salida')}</label>
                                            <input className='form-control form-control-solid' type="time" required value={sheet.checkOutTime} onChange={(e) => {handleSheetEditChange('checkOutTime', key, e.target.value)}}/>
                                        </div>
                                        <div className='col-md-4 col-sm-12'>
                                            <label className='required fw-bold fs-6 mb-2'>{t('Tarea')}</label>
                                            <Select placeholder={t('Seleccionar tarea')} options={selectedTask.options} value={selectedTask.options.find(t => t.value === sheet?.task)} onChange={(selectedValue) => {handleSheetEditChange('task', key, selectedValue.value)}}/>
                                        </div>
                                        <div className='col-md-2 col-sm-12 d-md-flex d-none align-items-end justify-content-center mb-md-1'>
                                            <button type='button' className='btn btn-danger' onClick={() => {handleEditDeleteSheet(key)}}><i className='fas fa-trash p-0' /></button>
                                        </div>
                                        <div className='col-md-2 col-sm-12 d-md-none d-grid gap-2'>
                                            <button type='button' className='btn btn-danger' onClick={() => {handleEditDeleteSheet(key)}}><i className='fas fa-trash p-0' /></button>
                                        </div>
                                    </div>
                                ))
                            :
                                <></>
                        }
                        {sheetsToCreate.map((sheet, key) => (
                        <div className='row my-3' key={key}>
                            <div className='col-md-3 col-sm-6'>
                                <label className="required fw-bold fs-6 mb-2">{t('Hora de entrada')}</label>
                                <input className='form-control form-control-solid' type="time" required value={sheet.checkInTime} onChange={(e) => {handleSheetCreateChange('checkInTime', key, e.target.value)}}/>
                            </div>
                            <div className='col-md-3 col-sm-6'>
                                <label className="required fw-bold fs-6 mb-2">{t('Hora de salida')}</label>
                                <input className='form-control form-control-solid' type="time" required value={sheet.checkOutTime} onChange={(e) => {handleSheetCreateChange('checkOutTime', key, e.target.value)}}/>
                            </div>
                            <div className='col-md-4 col-sm-12'>
                                <label className='required fw-bold fs-6 mb-2'>{t('Tarea')}</label>
                                <Select placeholder={t('Seleccionar tarea')} options={selectedTask.options} value={selectedTask.options?.find(t => t.value === sheet.task)} onChange={(selectedValue) => {handleSheetCreateChange('task', key, selectedValue.value)}}/>
                            </div>
                            <div className='col-md-2 col-sm-12 d-md-flex d-none align-items-end justify-content-center mb-md-1'>
                                <button type='button' className='btn btn-danger' onClick={() => {handleCreateDeleteSheet(key)}}><i className='fas fa-trash p-0' /></button>
                            </div>
                            <div className='col-md-2 col-sm-12 d-md-none d-grid gap-2'>
                                <button type='button' className='btn btn-danger' onClick={() => {handleCreateDeleteSheet(key)}}><i className='fas fa-trash p-0' /></button>
                            </div>
                        </div>
                        ))}
                        <div className='d-grid gap-2'>
                        <button type='button' className='btn btn-warning' onClick={() => {setSheetsToCreate([...sheetsToCreate, {checkInTime: '', checkOutTime: '', task: null}])}}>{t('Añadir ficha')}</button>
                        </div>
                    </div>
                </div>
                </div>
            }
            {/*end::Absences*/}
            {/* begin::Submit */}
            {
                selectedUser.selected && confirmDate &&
                <div className='col-12'>
                <div className="card h-100">
                    <div className="card-body p-9 d-grid gap-2">
                    <button type='button' className='btn btn-primary' onClick={() => {handleSubmit()}}>{t('Guardar')}</button>
                    </div>
                </div>
                </div>
            }
            {/* end::Submit */}
            </div>
        </div>
        {/*end::Steps*/}
        </div>
        {/*end::Container*/}
    </div>
    )
}