import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/UseAuth';
import { getTasks } from '../../actions/taskActions';
import { checkSheetsAmongDates } from '../../actions/sheetActions';
import { getUsers, getUserSupervisor } from '../../actions/userActions';
import { CustomDataTable } from '../../components/datatables/CustomDataTable';
import { Modal } from 'react-bootstrap';

export default function CheckSheets() {

    const {auth} = useAuth()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {users} = useSelector(state => state.users)
    const {tasks} = useSelector(state => state.tasks)
    const {sheets, loading} = useSelector(state => state.sheets)

    const [selectedStartDate, setSelectedStartDate] = useState(null)
    const [selectedEndDate, setSelectedEndDate] = useState(null)
    const [confirmDate, setConfirmDate] = useState(false)

    const [selectedUser, setSelectedUser] = useState(null)

    // When a date has been confirmed
    const handleConfirmDate = async () => {
        dispatch(checkSheetsAmongDates({start: selectedStartDate, end: selectedEndDate}))
        setConfirmDate(true)
        setSelectedUser(null)
    }

    useEffect(() => {
        if (auth) {
            if (auth.roles.indexOf('ROLE_ADMIN') !== -1) {
                dispatch(getUsers())
            } else {
                dispatch(getUserSupervisor(auth.user))
            }
            dispatch(getTasks())
        }
    }, [auth, dispatch])

    const columns = [
        {
			name: t('Usuario'),
			cell: user =>
				<div>
					{user.username}
				</div>,
			selector: (user) => user.username,
			sortable: true,
		}, {
			name: t('Nombre y apellidos'),
			cell: user =>
				<div>
					{
						user.firstName 
						?
							user.firstName + ' '
						:
							<></>
					}
					{
						user.lastName 
						?
							user.lastName
						:
							<></>
					}
				</div>,
			selector: (user) => user.firstName+' '+user.lastName,
			sortable: true,
		}, {
			name: t('Empleo'),
			cell: user =>
				<div>
					{user.employ}
				</div>,
			selector: (user) => user.employ,
			sortable: true,
		}, {
			name: t('Departamento'),
			cell: user =>
				<div>
					{user.departments.map(d => d.name).join(', ')}
				</div>,
			selector: (user) => user.employ,
			sortable: true,
		},
        {
            name: t("Acciones"),
            cell: user => 
                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={() => setSelectedUser(user.id)}>
                    <i className="bi bi-eye fs-2"/>
                </span>,
            selector: user => user.id,
            sortable: false
        }
    ]

    return (
        <div>
        {/*begin::Toolbar*/}
        <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
        {/*begin::Container*/}
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
            {/*begin::Page title*/}
            <div className="page-title d-flex flex-column me-3">
            {/*begin::Title*/}
            <h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Revisión de fichajes')}</h1>
            {/*end::Title*/}
            {/*begin::Breadcrumb*/}
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                <Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item">
                <span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                <Link to='/admin' className="text-white text-hover-primary">{t('Admin')}</Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item">
                <span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white">{t('Revisar Fichaje')}</li>
                {/*end::Item*/}
            </ul>
            {/*end::Breadcrumb*/}
            </div>
            {/*end::Page title*/}
        </div>
        {/*end::Container*/}
        </div>
        {/*end::Toolbar*/}
        {/*begin::Container*/}
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        {/*begin::Steps*/}
        <div className="content flex-row-fluid" id="kt_content">
            <div className="row g-6 g-xl-9">
                <div className="col-12">
                    <div className="card h-100">
                        <div className="card-body p-9">
                        <div className="fs-2x fw-bolder">{t('Selección de fechas')}</div>
                        <div className="fs-4 fw-bold text-gray-400 mb-7">{t('Seleccione un rango de fechas para continuar')}</div>
                            <div className='row'>
                                <div className='col-5'>
                                    <label htmlFor='start' className='form-label'>{t('Fecha de inicio')}</label>
                                    <input type="date" className="form-control form-control-solid mb-5" name="start" value={selectedStartDate || ''} onChange={(e) => {setSelectedStartDate(e.target.value); setConfirmDate(false)}}/>
                                </div>
                                <div className='col-2 d-flex align-items-center justify-content-center'>
                                    <i className='fa fa-arrow-right fa-lg'/>
                                </div>
                                <div className='col-5'>
                                    <label htmlFor='start' className='form-label'>{t('Fecha de fin')}</label>
                                    <input type="date" className="form-control form-control-solid mb-5" name="end" value={selectedEndDate || ''} onChange={(e) => {setSelectedEndDate(e.target.value); setConfirmDate(false)}}/>
                                </div>
                            </div>
                            <div className='d-grid gap-2'>
                            <button type='button' className='btn btn-info' onClick={handleConfirmDate}>{t('Seleccionar fecha')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    confirmDate && users &&
                    <div className="col-12">
                        <div className="card h-100">
                            <div className="card-body p-9">
                            <div className="fs-2x fw-bolder">{t('Revisión de fichajes')}</div>
                            <div className="fs-4 fw-bold text-gray-400 mb-7">{t('Vea la información de cada ficha del usuario')}</div>
                                {
                                    loading
                                    ?
                                        <span className='spinner-border align-middle ms-2' />
                                    :
                                        <CustomDataTable columns={columns} data={users.filter(u => sheets[u.id] !== null && sheets[u.id] !== undefined)} />
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        {/*end::Steps*/}
        </div>
        {/*end::Container*/}
        <Modal show={selectedUser} size='lg' backdrop='static' >
            <Modal.Header closeButton onClick={() => setSelectedUser(null)} >
                <Modal.Title className='fw-bolder fs-2'>{`${users?.find(u => u.id === selectedUser)?.firstName || '' } ${users?.find(u => u.id === selectedUser)?.lastName || '' } (${users?.find(u => u.id === selectedUser)?.username || '' })`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    {
                        sheets && sheets[selectedUser]?.map((sheet, key) => (
                            <div className='col-sm-12 col-md-6 mb-5' key={key}>
                                <div className='card border border-2'>
                                    <div className='card-body'>
                                        <div className='fw-bolder mb-3'>{sheet.date || ''}</div>
                                        <div className='row'>
                                            <div className='col-5 mb-3'>
                                                <div className='mb-3'>
                                                    <label className='fw-bold'>{t("Hora de entrada")}</label>
                                                </div>
                                                <p style={sheet.editedCheckInTime || sheet.editedDeleted ? {textDecoration: 'line-through'} : {}}>{sheet.checkInTime}</p>
                                                {
                                                    sheet.editedCheckInTime &&
                                                        <p className='mt-2' style={sheet.editedDeleted ? {textDecoration: 'line-through'} : {}}>{sheet.editedCheckInTime}</p>
                                                }
                                            </div>
                                            <div className='col-2 d-flex align-items-center justify-content-center mb-3'>
                                                <i className='fa fa-arrow-right fa-lg'/>
                                            </div>
                                            <div className='col-5 text-end mb-3'>
                                                <div className='mb-3'>
                                                    <label className='fw-bold'>{t("Hora de salida")}</label>
                                                </div>
                                                <p style={sheet.editedCheckOutTime || sheet.editedDeleted ? {textDecoration: 'line-through'} : {}}>{sheet.checkOutTime}</p>
                                                {
                                                    sheet.editedCheckOutTime &&
                                                        <p className='mt-2' style={sheet.editedDeleted ? {textDecoration: 'line-through'} : {}}>{sheet.editedCheckOutTime}</p>
                                                }
                                            </div>
                                            {
                                                (sheet.task || sheet.editedTask) && tasks &&
                                                    <>
                                                        <hr/>
                                                        <div className='col-12'>
                                                            {
                                                                sheet.task &&
                                                                    <p style={sheet.editedTask || sheet.editedDeleted ? {textDecoration: 'line-through'} : {}}>{tasks.find(t => t.id === sheet.task)?.name}</p>
                                                            }
                                                            {
                                                                sheet.editedTask &&
                                                                    <p style={sheet.editedDeleted ? {textDecoration: 'line-through'} : {}}>{tasks.find(t => t.id === sheet.editedTask)?.name}</p>
                                                            }
                                                        </div>
                                                    </>
                                            }
                                            {
                                                sheet.checkInLongitude && sheet.checkInLatitude &&
                                                <>
                                                    <div className='my-5'>
                                                        <label className='fw-bold'>{t("Ubicación de entrada")}</label>
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{__html: '<iframe width="100%" height="200" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=' + sheet.checkInLatitude + ',%20' + sheet.checkInLongitude + '+(location)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>'}}></div>
                                                </>
                                            }
                                            {
                                                sheet.checkOutLongitude && sheet.checkOutLatitude &&
                                                <>
                                                    <div className='my-5'>
                                                        <label className='fw-bold'>{t("Ubicación de salida")}</label>
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{__html: '<iframe width="100%" height="200" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=' + sheet.checkOutLatitude + ',%20' + sheet.checkOutLongitude + '+(location)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>'}}></div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Modal.Body>
        </Modal>
    </div>
    )
}