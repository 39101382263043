import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import useAuth from '../../hooks/UseAuth'
import { sortNotifications } from '../../services/sortNotifications'
import { useTranslation } from 'react-i18next'
import { getUser, getUserSupervisor } from '../../actions/userActions'
//import { getNotifications } from '../../actions/notificationActions'

export default function Menu() {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {user, supervisor} = useSelector(state => state.users)
	const [userNotifications, setUserNotifications] = useState(null)
	const [aUserNotifications, setAUserNotifications] = useState(null)

	const changeLanguageHandler=(lang)=>{
		i18n.changeLanguage(lang)
	}
	
	useEffect(()=>{
		if(auth.user){
			dispatch(getUser(auth.user, true))
			dispatch(getUserSupervisor(auth.user))
		}
	}, [auth, dispatch])

	useEffect(()=>{
		setUserNotifications()
		setAUserNotifications(sortNotifications(userNotifications))
	}, [user])

	return (
		<>
		<div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
		{/*begin::Navbar*/}
		<div className="d-flex align-items-stretch" id="kt_header_nav">
			{/*begin::Menu wrapper*/}
			<div className="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
			{/*begin::Menu*/}
			<div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
				<NavLink to={"/"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
				<span className="menu-link py-3">
					<div className="menu-title" >{t('Inicio')}</div>
					<span className="menu-arrow d-lg-none" />
				</span>
				</NavLink>
				<NavLink to={"/correctedsheets"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
					<span className="menu-link py-3">
						<span className="menu-title">{t('Aceptación de fichajes')}</span>
						<span className="menu-arrow d-lg-none" />
					</span>
				</NavLink>
				<NavLink to={"/vacations"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
					<span className="menu-link py-3">
						<span className="menu-title">{t('Vacaciones')}</span>
						<span className="menu-arrow d-lg-none" />
					</span>
				</NavLink>
				{
					user?.departmentsManaged && user?.departmentsManaged.length > 0 &&
					<>
						<div className={`d-flex btn btn-active-light-primary menu-dropdown ${({isActive}) => (isActive ? 'here show' : '')}`} data-bs-toggle="dropdown" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
							<span className="menu-link py-3 text-light">
								<span className="menu-title">{t('Departamento')}</span>
								<span className="menu-arrow d-lg-none" />
							</span>
						</div>
						<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px dropdown-menu">
							<div className="menu-item px-3 my-1">
								<NavLink to={"/vacation/managed"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
									<span className="menu-link py-3">
										<span className="menu-title">{t('Información de vacaciones')}</span>
										<span className="menu-arrow d-lg-none" />
									</span>
								</NavLink>
							</div>
							<div className="menu-item px-3 my-1">
								<NavLink to={"/schedule/managed"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
									<span className="menu-link py-3">
										<span className="menu-title">{t('Información de horario')}</span>
										<span className="menu-arrow d-lg-none" />
									</span>
								</NavLink>
							</div>
						</div>
					</>
				}
				{
				supervisor && supervisor.length > 0 &&
					<>
						<div className={`btn btn-active-light-primary menu-dropdown ${({isActive}) => (isActive ? 'here show' : '')}`} data-bs-toggle="dropdown" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
							<span className="menu-link py-3 text-light">
								<span className="menu-title">{t('Gestión de fichajes')}</span>
								<span className="menu-arrow d-lg-none" />
							</span>
						</div>
						<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px dropdown-menu">
							<div className="menu-item px-3 my-1">
								<NavLink to={"/checksheets"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
									<span className="menu-link py-3">
										<span className="menu-title">{t('Revisión de fichajes')}</span>
										<span className="menu-arrow d-lg-none" />
									</span>
								</NavLink>
							</div>
							<div className="menu-item px-3 my-1">
								<NavLink to={"/checksheet"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
									<span className="menu-link py-3">
										<span className="menu-title">{t('Revisión de fichajes por usuario')}</span>
										<span className="menu-arrow d-lg-none" />
									</span>
								</NavLink>
							</div>
							<div className="menu-item px-3 my-1">
								<NavLink to={"/sheets"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
									<span className="menu-link py-3">
										<span className="menu-title">{t('Modificación de fichajes')}</span>
										<span className="menu-arrow d-lg-none" />
									</span>
								</NavLink>
							</div>
						</div>
						<div className={`btn btn-active-light-primary menu-dropdown ${({isActive}) => (isActive ? 'here show' : '')}`} data-bs-toggle="dropdown" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
							<span className="menu-link py-3 text-light">
								<span className="menu-title">{t('Gestión de vacaciones')}</span>
								<span className="menu-arrow d-lg-none" />
							</span>
						</div>
						<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px dropdown-menu">
							{
								supervisor && supervisor.length > 0 &&
								<>
									<div className="menu-item px-3 my-1">
										<NavLink to={"/vacation/check"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
											<span className="menu-link py-3">
												<span className="menu-title">{t('Revisión de vacaciones')}</span>
												<span className="menu-arrow d-lg-none" />
											</span>
										</NavLink>
									</div>
									<div className="menu-item px-3 my-1">
										<NavLink to={"/vacation/list"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
											<span className="menu-link py-3">
												<span className="menu-title">{t('Gestión de vacaciones')}</span>
												<span className="menu-arrow d-lg-none" />
											</span>
										</NavLink>
									</div>
								</>
							}
						</div>
						<NavLink to={"/payroll/list"} className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
							<span className="menu-link py-3">
								<span className="menu-title">{t('Gestión de nóminas')}</span>
								<span className="menu-arrow d-lg-none" />
							</span>
						</NavLink>
					</>
				}
				{/*
				<NavLink to="pruebas" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
				<span className="menu-link py-3">
					<span className="menu-title">Pruebas</span>
					<span className="menu-arrow d-lg-none" />
				</span>
				</NavLink> 
				*/}
				{/*begin::Admin*/}
				{
				auth?.roles?.find(role => role.includes(['ROLE_ADMIN']))
					?
					<div data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion me-lg-1">
					<span className="menu-link py-3" data-bs-toggle="dropdown">
						<span className="menu-title">{t('Admin')}</span>
						<span className="menu-arrow d-lg-none" />
					</span>
					<div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px dropdown-menu">
						{/*begin::Admin pannel*/}
						<ul className="dropdown-menu2 menu-item menu-lg-down-accordion">
						<li>
							{/*begin::Users button*/}
							<Link to={'/admin'} className="menu-link py-3">
							<span className="menu-icon">
								{/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg*/}
								<span className="svg-icon svg-icon-muted svg-icon-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M13.0021 10.9128V3.01281C13.0021 2.41281 13.5021 1.91281 14.1021 2.01281C16.1021 2.21281 17.9021 3.11284 19.3021 4.61284C20.7021 6.01284 21.6021 7.91285 21.9021 9.81285C22.0021 10.4129 21.5021 10.9128 20.9021 10.9128H13.0021Z" fill="currentColor"/>
									<path opacity="0.3" d="M11.0021 13.7128V4.91283C11.0021 4.31283 10.5021 3.81283 9.90208 3.91283C5.40208 4.51283 1.90209 8.41284 2.00209 13.1128C2.10209 18.0128 6.40208 22.0128 11.3021 21.9128C13.1021 21.8128 14.7021 21.3128 16.0021 20.4128C16.5021 20.1128 16.6021 19.3128 16.1021 18.9128L11.0021 13.7128Z" fill="currentColor"/>
									<path opacity="0.3" d="M21.9021 14.0128C21.7021 15.6128 21.1021 17.1128 20.1021 18.4128C19.7021 18.9128 19.0021 18.9128 18.6021 18.5128L13.0021 12.9128H20.9021C21.5021 12.9128 22.0021 13.4128 21.9021 14.0128Z" fill="currentColor"/>
								</svg>
								</span>
								{/*end::Svg Icon*/}
							</span>
							<span className="menu-title">{t('Panel Administración')}</span>
							</Link>
							{/*end::Users button*/}
						</li>
						</ul>
						{/*end::Admin pannel*/}
						{/*begin::Users*/}
						<ul className="dropdown-menu2 menu-item menu-lg-down-accordion">
						<li>
							{/*begin::Users button*/}
							<Link to={'/user/list'} className="menu-link py-3">
							<span className="menu-icon">
								{/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg*/}
								<i className="bi bi-people-fill fa-lg"></i>
								{/*end::Svg Icon*/}
							</span>
							<span className="menu-title">{t('Usuarios')}</span>
							</Link>
							{/*end::Users button*/}
						</li>
						</ul>
						{/*end::Users*/}
						{/*begin::Departments*/}
						<ul className="dropdown-menu2 menu-item menu-lg-down-accordion">
						<li>
							{/*begin::Departments button*/}
							<Link to={'/department/list'} className="menu-link py-3">
							<span className="menu-icon">
								{/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg*/}
								<i className="bi bi-diagram-3-fill fa-lg"></i>
								{/*end::Svg Icon*/}
							</span>
							<span className="menu-title">{t('Departamentos')}</span>
							</Link>
							{/*end::Departments button*/}
						</li>
						</ul>
						{/*end::Departments*/}
						{/*begin::Schedule*/}
						<ul className="dropdown-menu2 menu-item menu-lg-down-accordion">
						<li>
							{/*begin::Departments button*/}
							<Link to={'/schedule/list'} className="menu-link py-3">
							<span className="menu-icon">
								{/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg*/}
								<i className="bi bi-clock-history fa-lg"></i>
								{/*end::Svg Icon*/}
							</span>
							<span className="menu-title">{t('Horarios')}</span>
							</Link>
						</li>
						</ul>
						{/*end::Schedule*/}
						{/*begin::Task*/}
						{/* <ul className="dropdown-menu2 menu-item menu-lg-down-accordion">
						<li>
							<Link to={'/task/list'} className="menu-link py-3">
							<span className="menu-icon">
								<i className="bi bi-clipboard-check fa-lg"></i>
							</span>
							<span className="menu-title">{t('Tareas')}</span>
							</Link>
						</li>
						</ul> */}
						{/*end::Task*/}
						{/*begin::Absences*/}
						<ul className="dropdown-menu2 menu-item menu-lg-down-accordion">
						<li>
							{/*begin::Departments button*/}
							<Link to={'/absence/list'} className="menu-link py-3">
							<span className="menu-icon">
								{/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg*/}
								<i className="bi bi-calendar-x fa-lg" />
								{/*end::Svg Icon*/}
							</span>
							<span className="menu-title">{t('Ausencias')}</span>
							</Link>
						</li>
						</ul>
						{/*end::Absences*/}
						{/*begin::AbsenceTypes*/}
						<ul className="dropdown-menu2 menu-item menu-lg-down-accordion">
						<li>
							{/*begin::Departments button*/}
							<Link to={'/absenceType/list'} className="menu-link py-3">
							<span className="menu-icon">
								{/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg*/}
								<i className="bi bi-bookmark-x fa-lg" />
								{/*end::Svg Icon*/}
							</span>
							<span className="menu-title">{t('Tipos de ausencias')}</span>
							</Link>
						</li>
						</ul>
						<ul className='dropdown-menu2 menu-item menu-lg-down-accordion'>
							<li>
								<Link to='/festives' className='menu-link py-3'>
									<span className='menu-icon'>
										<i className="bi bi-calendar-week fa-lg"></i>
									</span>
									<span className='menu-title'>{t('Festivos')}</span>
								</Link>
							</li>
						</ul>
						{/*end::AbsenceTypes*/}
						{/*begin::Logs*/}
						<ul className="dropdown-menu2 menu-item menu-lg-down-accordion">
						<li>
							{/*begin::Departments button*/}
							<Link to={'/logs'} className="menu-link py-3">
							<span className="menu-icon">
								{/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg*/}
								<i className="bi bi-view-stacked fa-lg" />
								{/*end::Svg Icon*/}
							</span>
							<span className="menu-title">{t('Registros')}</span>
							</Link>
						</li>
						</ul>
						{/*end::Logs*/}
						{/*begin::Test*/}
						{/* <ul className="dropdown-menu2 menu-item menu-lg-down-accordion">
						<li>
							<Link to={'#'} className="menu-link py-3">
							<span className="menu-icon">
								<span className="svg-icon svg-icon-2">
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
									<path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="black" />
									<path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="black" />
									<path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="black" />
								</svg>
								</span>
							</span>
							<span className="menu-title">Test</span>
							<span className="menu-arrow" />
							</Link>
							<ul className="dropdown-menu2 dropdown-submenu menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-lg-4 w-lg-225px">
							<li className='menu-item menu-lg-down-accordion'>
								<Link to={'#'} className="menu-link py-3">
								<span className="menu-bullet">
									<span className="bullet bullet-dot" />
								</span>
								<span className="menu-title">Crear Usuario</span>
								<span className="menu-arrow" />
								</Link>
								<ul className="dropdown-menu2 dropdown-submenu menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-lg-4 w-lg-225px">
								<li className="menu-item">
									<Link to={'#'} className="menu-link py-3" href="../../demo2/dist/pages/blog/home.html">
									<span className="menu-bullet">
										<span className="bullet bullet-dot" />
									</span>
									<span className="menu-title">Listar Usuarios</span>
									</Link>
								</li>
								<li className="menu-item">
									<Link to={'#'} className="menu-link py-3" href="../../demo2/dist/pages/blog/post.html">
									<span className="menu-bullet">
										<span className="bullet bullet-dot" />
									</span>
									<span className="menu-title">Buscar Usuarios</span>
									</Link>
								</li>
								</ul>
							</li>
							<li className="menu-item">
								<Link to={'#'} className="menu-link py-3" href="../../demo2/dist/pages/about.html">
								<span className="menu-bullet">
									<span className="bullet bullet-dot" />
								</span>
								<span className="menu-title">Eliminar Usuario</span>
								</Link>
							</li>
							<li className="menu-item">
								<Link to={'#'} className="menu-link py-3" href="../../demo2/dist/pages/about.html">
								<span className="menu-bullet">
									<span className="bullet bullet-dot" />
								</span>
								<span className="menu-title">About Us</span>
								</Link>
							</li>
							</ul>
						</li>
						</ul> */}
						{/*begin::Test*/}
					</div>
					</div>
					: ''
				}
				{/*end::Admin*/}
			</div>
			{/*end::Menu*/}
			</div>
			{/*end::Menu wrapper*/}
		</div>
		{/*end::Navbar*/}
		{/*begin::Toolbar wrapper*/}
		<div className="topbar d-flex align-items-stretch flex-shrink-0">
			{/*begin::Notifications*/}
			<div className="d-flex align-items-center ms-1 ms-lg-3">
			{/*begin::Menu- wrapper*/}
			<div className="btn btn-icon btn-active-light-primary btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px pulse pulse-info" data-bs-toggle="dropdown" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
				{/*begin::Svg Icon | path: icons/duotune/general/gen022.svg*/}
				<span className="svg-icon svg-icon-1 ">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="currentColor"/>
					<rect x="6" y="12" width="7" height="2" rx="1" fill="currentColor"/>
					<rect x="6" y="7" width="12" height="2" rx="1" fill="currentColor"/>
				</svg>
				</span>
				{
				aUserNotifications?.find(notification => notification.view === false) 
				? <span className="pulse-ring border-3"></span>
				: ''
				}
				{/*end::Svg Icon*/}
			</div>
			{/*begin::Menu*/}
			<div className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px dropdown-menu">
				{/*begin::Heading*/}
				<div className="d-flex flex-column bgi-no-repeat rounded-top pb-6" style={{ backgroundImage: 'url("/assets/media/misc/pattern-1.jpg")' }}>
				{/*begin::Title*/}
				<h3 className="text-white fw-bold px-9 mt-12 mb-0">{t('Notificaciones')}
					{/* <CreateNotification /> */}
				</h3>
				<span className="ms-6 fs-8 text-white fw-bold opacity-75 ps-3 mb-6">{t('Entrantes')}</span>
				{/*end::Title*/}
				</div>
				{/*end::Heading*/}
				{/*begin::Tab content*/}
				<div className="tab-content">
				{/*begin::Tab panel*/}
				<div className="tab-pane fade show active" id="kt_topbar_notifications_1" role="tabpanel">
					{/*begin::Items*/}
					<div className="scroll-y mh-325px my-5 px-8">
					{/*begin::Item*/}
					{
						aUserNotifications?.map((notification, i)=>
						<Link key={i} to={`/notifications`} className="d-flex flex-stack py-4 bg-hover-light rounded-2">
							{/*begin::Section*/}
							<div className="d-flex align-items-center">
							{/*begin::Symbol*/}
							<div className="symbol symbol-35px me-4">
								<span className="symbol-label bg-light-primary">
								{
								notification.view 
								? <span className="svg-icon svg-icon-2 svg-icon-primary">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z" fill="currentColor"/>
										<path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="currentColor"/>
									</svg>
									</span>
								: <span className="svg-icon svg-icon-2 svg-icon-primary">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"/>
										<path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"/>
									</svg>
									</span>
								}
								</span>
							</div>
							{/*end::Symbol*/}
							{/*begin::Title*/}
							<div className="mb-0 me-2">
								<div className="fs-6 text-gray-800 text-hover-primary fw-bolder">{notification.title.length > 20 ? notification.title.substring(0,20)+'...' : notification.title}</div>
								<div className="text-gray-400 fs-7">{t('De')}: {notification.sendUser.username}</div>
							</div>
							{/*end::Title*/}
							</div>
							{/*end::Section*/}
							{/*begin::Label*/}
							<span className="badge badge-light fs-8">{notification.dateTimeFormated}</span>
							{/*end::Label*/}
						</Link>
						)
					}
					{/*end::Item*/}
					</div>
					{/*end::Items*/}
					{/*begin::View more*/}
					<div className="py-3 text-center border-top">
					<Link to={'/notifications'} className="btn btn-color-gray-600 btn-active-color-primary">{t('Ver todas')} 
						{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
						<span className="svg-icon svg-icon-5">
						<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
							<rect opacity="0.5" x={18} y={13} width={13} height={2} rx={1} transform="rotate(-180 18 13)" fill="black" />
							<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
						</svg>
						</span>
						{/*end::Svg Icon*/}</Link>
					</div>
					{/*end::View more*/}
				</div>
				{/*end::Tab panel*/}
				</div>
				{/*end::Tab content*/}
			</div>
			{/*end::Menu*/}
			{/*end::Menu wrapper*/}
			</div>
			{/*end::Notifications*/}
			{/*begin::Quick links*/}
			<div className="d-flex align-items-center ms-1 ms-lg-3">
			{/*begin::Menu wrapper*/}
			<div className="btn btn-icon btn-active-light-primary btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px" data-bs-toggle="dropdown" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
				{/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
				<span className="svg-icon svg-icon-1">
				<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
					<rect x={2} y={2} width={9} height={9} rx={2} fill="black" />
					<rect opacity="0.3" x={13} y={2} width={9} height={9} rx={2} fill="black" />
					<rect opacity="0.3" x={13} y={13} width={9} height={9} rx={2} fill="black" />
					<rect opacity="0.3" x={2} y={13} width={9} height={9} rx={2} fill="black" />
				</svg>
				</span>
				{/*end::Svg Icon*/}
			</div>
			{/*begin::Menu*/}
			<div className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px dropdown-menu">
				{/*begin::Heading*/}
				<div className="d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10" style={{backgroundImage: 'url("/assets/media/misc/pattern-1.jpg")'}}>
				{/*begin::Title*/}
				<h3 className="text-white fw-bold mb-3">{t('Menu de enlaces')}</h3>
				{/*end::Title*/}
				</div>
				{/*end::Heading*/}
				{/*begin:Nav*/}
				<div className="row g-0">
				{/*begin:Item*/}
				<div className="col-6">
					<Link to={'/absences'} className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
					{/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
					<span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
						<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
						<path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black" />
						<path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black" />
						</svg>
					</span>
					{/*end::Svg Icon*/}
					<span className="fs-4 fw-bold text-gray-800 mb-0">{t('Ausencias')}</span>
					</Link>
				</div>
				{/*end:Item*/}
				{/*begin:Item*/}
				<div className="col-6">
					<Link to={'/payrolls'} className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom">
					{/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
					<span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="currentColor"/>
						<path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="currentColor"/>
						</svg>
					</span>
					{/*end::Svg Icon*/}
					<span className="fs-4 fw-bold text-gray-800 mb-0">{t('Nóminas')}</span>
					</Link>
				</div>
				{/*end:Item*/}
				{/*begin:Item*/}
				<div className="col-6">
					<Link to={'/documents'} className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end">
					{/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
					<span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
						<path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor"/>
						</svg>
					</span>
					{/*end::Svg Icon*/}
					<span className="fs-4 fw-bold text-gray-800 mb-0">{t('Documentos')}</span>
					</Link>
				</div>
				{/*end:Item*/}
				{/*begin:Item*/}
				<div className="col-6">
					<Link to={'/schedule'} className="d-flex flex-column flex-center h-100 p-6 bg-hover-light">
					{/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
					<span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path opacity="0.3" d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z" fill="currentColor"/>
						<path d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z" fill="currentColor"/>
						<path d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z" fill="currentColor"/>
						</svg>
					</span>
					{/*end::Svg Icon*/}
					<span className="fs-4 fw-bold text-gray-800 mb-0">{t('Horario')}</span>
					</Link>
				</div>
				{/*end:Item*/}
				</div>
				{/*end:Nav*/}
				{/*begin::View more*/}
				<div className="py-2 text-center border-top">
				
				</div>
				{/*end::View more*/}
			</div>
			{/*end::Menu*/}
			{/*end::Menu wrapper*/}
			</div>
			{/*end::Quick links*/}
			<div className="d-flex align-items-center ms-1 ms-lg-3">
			{/*begin::Menu toggle*/}
			<div className="btn btn-icon btn-active-light-primary btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px" data-bs-toggle="dropdown" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
				<span className="svg-icon svg-icon-muted svg-icon-2x">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path opacity="0.3" d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z" fill="currentColor"/>
						<path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z" fill="currentColor"/>
					</svg>
				</span>
			</div>
			{/*begin::Menu toggle*/}
			{/*begin::Menu*/}
			<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-bold py-4 fs-6 w-50px dropdown-menu" style={{zIndex: 105, position: 'fixed', inset: '0px 0px auto auto', margin: 0, transform: 'translate(-70px, 60px)'}} data-popper-placement="bottom-end">
				{/*begin::Menu item*/}
				<div className="menu-item px-3 my-1">
					<Link to={'#'} className="menu-link px-3" onClick={() => changeLanguageHandler('es')}>
						<span className="menu-title">Es</span>
					</Link>
				</div>
				{/*end::Menu item*/}
				{/*begin::Menu item*/}
				<div className="menu-item px-3 my-1">
					<Link to={'#'} className="menu-link px-3" onClick={() => changeLanguageHandler('en')}>
						<span className="menu-title">En</span>
					</Link>
				</div>
				{/*end::Menu item*/}
			</div>
			{/*end::Menu*/}
			</div>
			{/*begin::User*/}
			<div className="d-flex align-items-center me-n3 ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
			{/*begin::Menu wrapper*/}
			<div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" data-bs-toggle="dropdown" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
				
				{user?.imageProfile
					?
						<img className="h-30px w-30px rounded" src={user.imageProfile} alt='avatar' />
					: 
						<></>
				}
			</div>
			{/*begin::Menu*/}
			<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px dropdown-menu">
				{/*begin::Menu item*/}
				<div className="menu-item px-3">
				<div className="menu-content d-flex align-items-center px-3">
					{/*begin::Avatar*/}
					<div className="symbol symbol-50px me-5">
					{user?.imageProfile
						?
							<img alt="Logo" src= {user.imageProfile} />
						: 
							<></>
					}
					</div>
					{/*end::Avatar*/}
					{/*begin::Username*/}
					<div className="d-flex flex-column">
					<div className="fw-bolder d-flex align-items-center fs-5">{auth?.username}
						{
						auth.roles?.includes('ROLE_ADMIN')
							? <span className="badge badge-light-danger fw-bolder fs-8 px-2 py-1 ms-2">Admin</span>
							: ''
						}
					</div>
					<div className="fw-bold text-muted text-hover-primary fs-7">{auth?.email}</div>
					</div>
					{/*end::Username*/}
				</div>
				</div>
				{/*end::Menu item*/}
				{/*begin::Menu separator*/}
				<div className="separator my-2" />
				{/*end::Menu separator*/}
				{/*begin::Menu item*/}
				<div className="menu-item px-5 my-1">
					<Link to="/profile" className="menu-link px-5">{t('Configurar Cuenta')}</Link>
				</div>
				{/*end::Menu item*/}
				{/*begin::Menu separator*/}
				<div className="separator my-2" />
				{/*end::Menu separator*/}
				{/*begin::Menu item*/}
				<div className="menu-item px-5">
				<Link to="/logout" className="menu-link px-5">{t('Cerrar Sesion')}</Link>
				</div>
				{/*end::Menu item*/}
			</div>
			{/*end::Menu*/}
			{/*end::Menu wrapper*/}
			</div>
			{/*end::User */}
			{/*begin::Aside mobile toggle*/}
			{/*end::Aside mobile toggle*/}
		</div>
		{/*end::Toolbar wrapper*/}
		</div>
		</>
	)
}
